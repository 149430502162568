<template>
  <div
    v-if="
      currentEntity.templates.length == 1 &&
      currentEntity.entity_type === 'GENERAL'
    "
  >
    <el-row class="template-error-body" v-if="showError">
      <p>{{ showErrorMessage }}</p>
    </el-row>
    <el-row v-else>
      <div v-if="currentTemplate == 'contacts'">
        <entity-contacts
          :contactType="currentEntity._id"
          :accountName="entityDataId"
          :entityDataId="currentEntity"
          :hideLabel="true"
        >
        </entity-contacts>
      </div>
      <entity-template-view
        v-else-if="showData && getCurrentStep && getFormOrFreeStyle"
        :templateData="getCurrentStep.templateInfo"
        :has-next="getCurrentStep.has_next"
        :entityDataId="entityDataId"
        :workflow-step-id="getCurrentStep._id"
        :template-data-id="getCurrentStep.templateDataId"
        :currentStep="getCurrentStep"
        :is-execute="isEdit"
        :isSaveAsDraft="isSaveAsDraft"
        :layout="layout"
        :currentEntity="currentEntity"
        :isEdit="isEdit"
        :fromScheduling=false
        :entityData="entityData"
        :currentMenu="currentMenu"
        :entitySettings="entitySettings"
        :entityDataExists="entityDataExists"
        :printEntityData="printEntityData"
        :fromPopup="true"
        :profilePicture="getUserprofilepicture"
        :saveTemp="saveTemp"
        :openNew="openNew"
        :allEntitiesDataFromParent="allEntitiesDataFromParent"
        :allFormTemplatesFromParent="allFormTemplatesFromParent"
        :allCompanyUserTypesDataFromParent="allCompanyUserTypesDataFromParent"
        v-on:goto-next="moveToNextTemplate"
        v-on:refresh-page="refreshThePage"
        v-on:getDataFromTemplatesPreview="getDataFromPreviewTemplate"
        @closeParent="closeParent"
        :getSuggestionFormData="getSuggestionFormData"
        :isFromDialog="isFromDialog"
      >
      </entity-template-view>
      <fields-preview-form-style
        v-else-if="showData && getCurrentStep && !getFormOrFreeStyle"
        :templateData="getCurrentStep.templateInfo"
        :has-next="getCurrentStep.has_next"
        :entityDataId="entityDataId"
        :workflow-step-id="getCurrentStep._id"
        :template-data-id="getCurrentStep.templateDataId"
        :currentStep="getCurrentStep"
        :isSaveAsDraft="isSaveAsDraft"
        :is-execute="isEdit"
        :layout="layout"
        :fromScheduling=false
        :currentEntity="currentEntity"
        :entitySettings="entitySettings"
        :isEdit="isEdit"
        :entityData="entityData"
        :currentMenu="currentMenu"
        :entityDataExists="entityDataExists"
        :printEntityData="printEntityData"
        :fromPopup="true"
        :profilePicture="getUserprofilepicture"
        :saveTemp="saveTemp"
        :openNew="openNew"
        :allEntitiesDataFromParent="allEntitiesDataFromParent"
        :allFormTemplatesFromParent="allFormTemplatesFromParent"
        :allCompanyUserTypesDataFromParent="allCompanyUserTypesDataFromParent"
        v-on:goto-next="moveToNextTemplate"
        v-on:refresh-page="refreshThePage"
        v-on:getDataFromTemplatesPreview="getDataFromPreviewTemplate"
        @closeParent="closeParent"
        :getSuggestionFormData="getSuggestionFormData"
        :isFromDialog="isFromDialog"
      >
      </fields-preview-form-style>
    </el-row>
  </div>
  <div v-loading="loading" v-else>
    <div class="all-template-container">
      <el-tabs v-model="currentTemplate" @tab-click="changeTemplate">
        <el-tab-pane
          v-for="(template, i) in allTemplates"
          :label="getStepsObject[template.slug].senderAlias || template.name"
          :key="template._id + i"
          :name="template._id"
        >
        </el-tab-pane>
        <el-tab-pane
          v-if="
            currentEntity &&
            currentEntity.entity_type === 'BUSINESS' &&
            checkIsFilteredData
          "
          name="contacts"
          label="Contacts"
        >
        </el-tab-pane>
      </el-tabs>
      <el-row class="template-error-body" v-if="showError">
        <p>{{ showErrorMessage }}</p>
      </el-row>
      <el-row class="template-body" v-else>
        <div v-if="currentTemplate == 'contacts'">
          <entity-contacts
            :contactType="currentEntity._id"
            :accountName="entityDataId"
            :entityDataId="currentEntity"
            :hideLabel="true"
          >
          </entity-contacts>
        </div>
        <entity-template-view
          v-else-if="showData && getCurrentStep && isSplitView"
          :isSplitView="true"
          :templateData="getCurrentStep.templateInfo"
          :has-next="getCurrentStep.has_next"
          :entityDataId="entityDataId"
          :workflow-step-id="getCurrentStep._id"
          :template-data-id="getCurrentStep.templateDataId"
          :currentStep="getCurrentStep"
          :is-execute="isEdit"
          :layout="layout"
          :currentEntity="currentEntity"
          :isEdit="isEdit"
          :entityData="entityData"
          :currentMenu="currentMenu"
          :entityDataExists="entityDataExists"
          :printEntityData="printEntityData"
          :profilePicture="getUserprofilepicture"
          :saveTemp="saveTemp"
          :openNew="openNew"
          :allEntitiesDataFromParent="allEntitiesDataFromParent"
          :allFormTemplatesFromParent="allFormTemplatesFromParent"
          :allCompanyUserTypesDataFromParent="allCompanyUserTypesDataFromParent"
          v-on:goto-next="moveToNextTemplate"
          v-on:refresh-page="refreshThePage"
          v-on:getDataFromTemplatesPreview="getDataFromPreviewTemplate"
          @closeParent="closeParent"
          :getSuggestionFormData="getSuggestionFormData"
          :isFromDialog="isFromDialog"
        >
        </entity-template-view>
        <entity-template-view
          v-else-if="showData && getCurrentStep"
          :templateData="getCurrentStep.templateInfo"
          :has-next="getCurrentStep.has_next"
          :entityDataId="entityDataId"
          :workflow-step-id="getCurrentStep._id"
          :template-data-id="getCurrentStep.templateDataId"
          :currentStep="getCurrentStep"
          :is-execute="isEdit"
          :layout="layout"
          :currentEntity="currentEntity"
          :isEdit="isEdit"
          :entityData="entityData"
          :currentMenu="currentMenu"
          :entityDataExists="entityDataExists"
          :printEntityData="printEntityData"
          :profilePicture="getUserprofilepicture"
          :saveTemp="saveTemp"
          :openNew="openNew"
          :allEntitiesDataFromParent="allEntitiesDataFromParent"
          :allFormTemplatesFromParent="allFormTemplatesFromParent"
          :allCompanyUserTypesDataFromParent="allCompanyUserTypesDataFromParent"
          v-on:goto-next="moveToNextTemplate"
          v-on:refresh-page="refreshThePage"
          v-on:getDataFromTemplatesPreview="getDataFromPreviewTemplate"
          @closeParent="closeParent"
          :getSuggestionFormData="getSuggestionFormData"
          :isFromDialog="isFromDialog"
        >
        </entity-template-view>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
export default {
  props: [
    'isSplitView',
    "currentEntity",
    "entityData",
    "isEdit",
    "isSaveAsDraft",
    "layout",
    "entityDataExists",
    "printEntityData",
    "profilePicture",
    // "isApplicationUserSide",
    "saveTemp",
    "openNew",
    "entitySettings",
    "allEntitiesDataFromParent",
    "allFormTemplatesFromParent",
    "allCompanyUserTypesDataFromParent",
    "getSuggestionFormData",
    "isFromDialog",
  ],
  data() {
    return {
      showTabChangeDialog:false,
      initialFormData:{},
      Mounted:false,
      isTabChangeConfirmed:false,
      nextTab:null,
      prevTab:null,
      loading: false,
      currentStep: 0,
      currentTemplate: "",
      showData: false,
      showError: false,
      showErrorMessage: "Invalid step",
      isApplicationUserSide: false,
      formData: {},
    };
  },
  watch: {
    activeTab() {
      if (this.activeTab?.template_id) {
        this.currentTemplate = this.activeTab.template_id;
      }
    },
    formData: {
      handler() {
        this.$emit("getDataFromEntityTemplateView", this.formData);
      },
      deep: true,
      immediate: true,
    },
  },
  mixins: [NavigationHelper, templateBuilderHelper],
  components: {
    EntityTemplateView: () =>
      import("@/components/templates/templateFieldsPreview"),
    FieldsPreviewFormStyle: () =>
      import("@/components/templates/templateFieldsPreviewFormStyle"),
    EntityContacts: () => import("./EntityContacts.vue"),
  },
  computed: {
    getFormOrFreeStyle() {
      if (
        this.currentEntity.templates &&
        this.currentEntity?.templates?.length === 1
      ) {
        const templateInfo = this.currentEntity.templates[0].templateInfo;
        // Check if 'free_style' key does not exist or exists and is true
        if (
          !templateInfo ||
          templateInfo.free_style === undefined ||
          templateInfo.free_style === true
        ) {
          return true;
        }
      }

      // Default return false if conditions are not met
      return false;
    },
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
      "getEntityPrimaryData",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType"]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    fieldsData() {
      if (this.getCurrentStep?.templateInfo?.sections[0]?.fields) {
        return this.getCurrentStep?.templateInfo.sections[0].fields;
      }
      return [];
    },
    entityDataId() {
      if (this.entityData?._id) {
        return this.entityData._id;
      }
      return null;
    },
    currentSlug() {
      if (this.activeTab?.templateInfo?.slug) {
        return this.activeTab?.templateInfo?.slug;
      }
      return "";
    },
    activeTab() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates[this.currentStep]
      ) {
        return this.getCurrentEntityTemplates[this.currentStep];
      }
      return null;
    },
    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        if (step && step.type !== "TEMPLATE") {
          return step;
        }
        return step;
      }

      return null;
    },
    getStepsObject() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates.length
      ) {
        const data = {};
        this.getCurrentEntityTemplates.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;
          data[step.templateInfo.slug] = step;
        });
        return data;
      }
      return {};
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.query?.menu_id &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              } else if (el.children) {
                (el.children || []).forEach((ele) => {
                  if (ele._id == id) {
                    menu = ele;
                  }
                });
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getCurrentEntityTemplates() {
      if (this.currentEntity && this.currentEntity.templates) {
        if (this.isApplicationUserSide) {
          if (
            this.$route.query?.menu_id &&
            this.getEntityPermission &&
            this.currentMenu &&
            this.getEntityMenu?.menu_list
          ) {
            if (
              this.currentMenu?.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              this.getEntityPermission.other_entities[this.currentEntity._id]
                ?.templates
            ) {
              return this.currentEntity.templates.filter((e) => {
                if (
                  this.getEntityPermission.other_entities[
                    this.currentEntity._id
                  ].templates[e.template_id] &&
                  this.getEntityPermission.other_entities[
                    this.currentEntity._id
                  ].templates[e.template_id].indexOf("VIEW") != -1
                ) {
                  return true;
                }
              });
            } else if (
              this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
              this.getEntityPermission.relational_entities &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.templates
            ) {
              return this.currentEntity.templates.filter((e) => {
                if (
                  this.getEntityPermission.relational_entities[
                    this.currentEntity._id
                  ].templates[e.template_id] &&
                  this.getEntityPermission.relational_entities[
                    this.currentEntity._id
                  ].templates[e.template_id].indexOf("VIEW") != -1
                ) {
                  return true;
                }
              });
            } else if (
              this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
              this.currentMenu?.nested_relational_entity &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.templates
            ) {
              return this.currentEntity.templates.filter((e) => {
                if (
                  this.getEntityPermission.nested_relational_entities[
                    this.currentMenu.nested_relational_entity
                  ]?.templates[e.template_id] &&
                  this.getEntityPermission.nested_relational_entities[
                    this.currentMenu.nested_relational_entity
                  ]?.templates[e.template_id].indexOf("VIEW") != -1
                ) {
                  return true;
                }
              });
            }
            return [];
          } else if (this.getEntityPermission?.other_entities) {
            return this.currentEntity.templates.filter((e) => {
              if (
                this.getEntityPermission.other_entities[this.currentEntity._id]
                  ?.templates?.[e.template_id] &&
                this.getEntityPermission.other_entities[
                  this.currentEntity._id
                ].templates[e.template_id].indexOf("VIEW") != -1
              ) {
                return true;
              }
            });
          }
          return this.currentEntity.templates;
        } else {
          if (
            this.$route.query?.filter &&
            this.getSingleFilterData?.permissions?.templates &&
            this.currentEntity._id == this.getSingleFilterData.entity_id
          ) {
            return this.currentEntity.templates.filter(
              (e) =>
                this.getSingleFilterData?.permissions?.templates.indexOf(
                  e.template_id
                ) != -1
            );
          }
          return this.currentEntity.templates;
        }
      }
      return [];
    },
    templateDataId() {
      if (
        this.activeTab?.template_id &&
        this.getEntityPrimaryData?.templates_data
      ) {
        let templateDataId = (
          this.getEntityPrimaryData.templates_data || []
        ).find((e) => e.template_id == this.activeTab.template_id);
        if (templateDataId?.template_data_id) {
          return templateDataId?.template_data_id;
        }
      }
      return null;
    },
    allTemplates() {
      if (
        this.getCurrentEntityTemplates &&
        this.getCurrentEntityTemplates?.length
      ) {
        return this.getCurrentEntityTemplates.map((te) => {
          return { ...te.templateInfo };
        });
      }
      return [];
    },
    checkIsFilteredData() {
      if (
        (this.$route.query?.filter &&
          this.getSingleFilterData &&
          this.currentEntity &&
          this.currentEntity._id == this.getSingleFilterData.entity_id) ||
        this.isApplicationUserSide
      ) {
        return false;
      }
      return true;
    },
    getUserprofilepicture() {
      return this.profilePicture;
    },
  },
  async mounted() {
    console.log(this.isSplitView,"split view....")
    if (
      this.$route.name == "ApEntityExecute" ||
      this.$route.name == "ApPreviewEntityData"
    ) {
      this.isApplicationUserSide = true;
    }
    if (this.$route.query.stepId) {
      if (this.allTemplates.length <= this.$route.query.stepId) {
        this.showError = true;
      } else {
        this.currentStep = this.$route.query.stepId;
      }
    }
    if (this.activeTab?.template_id) {
      this.currentTemplate = this.activeTab.template_id;
    }
    if (this.getActiveContactType?.contact_type?._id) {
      // let activeWorkspace = this.getActiveContactType?.contact_type?._id;
      await Promise.all([
        // this.$store.dispatch(
        //   "applicationUsersPermission/fetchPermissionsEntityById",
        //   { id: this.getActiveContactType.contact_type._id }
        // ),
        // this.$store.dispatch(
        //   "menuManagementV2/fetchMenuByEntity",
        //   activeWorkspace
        // ),
      ]);
    }
    this.showData = true;
    await this.checkUrlType();
  },
  methods: {
    deepEqual(obj1, obj2) {
      if (obj1 === obj2) return true;
      if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
      ) {
        return false;
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!keys2.includes(key)) return false;
        if (!this.deepEqual(obj1[key], obj2[key])) return false;
      }
      return true;
    },

    getDataFromPreviewTemplate(data) {
      this.formData = {
        ...data,
      };
    },
    closeParent() {
      this.$emit("closeParent");
    },
    async refreshThePage(reload) {
      this.showError = false;
      this.showData = false;
      this.loading = true;
      if (reload) {
        this.$emit("refresh-page", reload);
      }
      if (this.$route.query.stepId) {
        if (this.allTemplates.length <= this.$route.query.stepId) {
          this.showError = true;
        } else {
          this.currentStep = this.$route.query.stepId;
        }
      }
      if (this.activeTab?.template_id) {
        this.currentTemplate = this.activeTab.template_id;
      }
      setTimeout(() => {
        this.showData = true;
        this.loading = false;
      }, 1000);
    },
    async moveToNextTemplate() {
      if (this.getCurrentStep?.has_next) {
        this.currentStep = this.getCurrentEntityTemplates.findIndex(
          (e) => e.template_id == this.currentTemplate
        );
        let type = this.isApplicationUserSide ? this.$route.query.type : "";
        let query = this.getNavigationQuery(this.$route.query);
        query["stepId"] = this.currentStep++;
        if (this.isApplicationUserSide) {
          query["type"] = type;
        }
        this.$router.push({
          name: this.$route.name,
          query: query,
        });
        this.showError = false;
        this.showData = false;
        this.loading = true;
        setTimeout(() => {
          this.showData = true;
          this.loading = false;
        }, 1000);
      } else {
        let menuItem = null;
        if (this.$route?.query?.redirect) {
          menuItem = this.getMenuItem(this.$route.query.redirect);
        }
        if (menuItem != null) {
          this.goToMenuItem(menuItem, menuItem.type == "Child" ? true : false);
        } else if (this.$route?.query?.addNew == "true") {
          this.$router.push({ path: "/ap/dashboard" });
        } else {
          let tempQuery = this.getNavigationQuery(this.$route.query);
          // if (this.$route?.query?.routeType) {
          //   tempQuery["routeType"] = this.$route.query.routeType;
          // }
          // if (this.$route?.query?.viewType) {
          //   tempQuery["viewType"] = this.$route.query.viewType;
          // }
          if (this.isApplicationUserSide) {
            let menu_id = this.$route?.query?.menu_id;
            if (menu_id) {
              this.$router.push({
                path: "/ap/entity/" + this.currentEntity._id + "/" + menu_id,
                query: tempQuery,
              });
            } else {
              this.$router.push({
                path: "/ap/dashboard",
              });
            }
          } else {
            if (this.$route.query?.fromCustom) {
              this.$router.push({
                path: "/dashboard",
              });
            } else {
              if (this.$route.query?.filter) {
                tempQuery["filter"] = this.$route.query.filter;
              }
              if (this.$route?.query?.viewType) {
                tempQuery["viewType"] = this.$route.query.viewType;
              }
              let UrlPath = "";
              if (this.getIframeUrlInfo != null) {
                UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`;
              } else {
                UrlPath = "/entity/" + this.currentEntity._id;
              }
              this.$router.push({
                path: UrlPath,

                query: tempQuery,
              });
            }
          }
        }
      }
    },
    async changeTemplate() {
      this.currentStep = this.getCurrentEntityTemplates.findIndex(
        (e) => e.template_id == this.currentTemplate
      );
      const { page, pageSize } = this.$route.query;
      let type = this.isApplicationUserSide ? this.$route.query.type : "";
      let query = this.getNavigationQuery(this.$route.query);
      if (this.$route.query?.board_field) {
        query["board_field"] = this.$route.query.board_field;
      }
      if (this.$route.query?.index) {
        query["index"] = this.$route.query.index;
      }
      if (this.currentStep > -1) {
        query["stepId"] = this.currentStep;
      }
      if (this.isApplicationUserSide) {
        query["type"] = type;
      }
      let UrlRoute = "";
      if (this.getIframeUrlInfo != null) {
        UrlRoute = "EntityDetailedView2_External";
      } else {
        UrlRoute = this.$route.name;
      }
      this.$router.push({
        name: UrlRoute,
        query: { ...query, page, pageSize },
      });
      this.showError = false;
      this.showData = false;
      this.loading = true;
      setTimeout(() => {
        this.showData = true;
        this.loading = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.template-error-body {
  text-align: center !important;
  margin-top: 100px;
  color: red;
  font-size: 24px;
  height: 300px;
}
.all-template-container {
  border: 2px solid #e6e6e6;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 20px;
  padding: 10px;
  .template-heading {
    .template-title {
      color: #303133;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      margin-left: 5px;
    }
  }
}
</style>